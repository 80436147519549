import { Grid, makeStyles, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import React, { FunctionComponent, useContext } from 'react';
import { Redirect, Route, Switch, BrowserRouter } from 'react-router-dom';
import { Data as WebsiteData, DataType } from '../../api/dtos';

export interface IDataProps {
    type?: DataType;
    value?: string;
    label: string;
    name: string;
}

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(1.5, 3),

        '&:nth-child(even)': {
            background: theme.palette.grey[100]
        }
    },
    label: {
    },
    labelText: {
        fontWeight: 'bold'
    },
    value: {
    }
}));

const Data: FunctionComponent<IDataProps> = ({ type, value, label, name }) => {
    const classes = useStyles();

    let adjustedValue = value;

    if (!adjustedValue) {
        return null;
    }

    if (type === DataType.Boolean) {
        if (adjustedValue === 'True') {
            adjustedValue = 'Yes'
        }

        if (adjustedValue === 'False') {
            adjustedValue = 'No'
        }
    }

    if (type === DataType.DateTime || type === DataType.DateTimeOffset) {
        adjustedValue = DateTime.fromISO(adjustedValue as string).toFormat('dd/MM/yyyy hh:mm:ss a');
    }

    if (type === DataType.Currency) {
        adjustedValue = '$' + parseFloat(adjustedValue as string).toFixed(2);
    }

    return (
        <Grid container className={classes.container}>
            <Grid
                item
                className={classes.label}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={2}
            >
                <Typography className={classes.labelText}>
                    {label}
                </Typography>
            </Grid>
            <Grid
                item
                className={classes.value}
                xs={12}
                sm={6}
                md={8}
                lg={9}
                xl={10}
            >
                <Typography>
                    {adjustedValue}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Data;
