import { fade, makeStyles, Typography } from '@material-ui/core';
import React, { FunctionComponent, useCallback, useContext } from 'react';
import { Redirect, Route, Switch, BrowserRouter, useHistory } from 'react-router-dom';
import Loading from '../components/layout/Loading';
import Error from '../components/layout/Error';
import useDonations from '../hooks/useDonations';
import { GridColDef, GridRowParams } from '@material-ui/data-grid';
import Table from '../components/table/Table';
import { DateTime } from 'luxon';
import clsx from 'clsx';


const useStyles = makeStyles((theme) => ({
    error: {
        padding: theme.spacing(3, 0)
    },
    cell: {
        cursor: 'pointer',

        '&:focus': {
            outline: 'none !important'
        }
    },
    row: {
    },
    incomplete: {
        background: fade(theme.palette.warning.main, 0.15)
    },
    failed: {
        background: fade(theme.palette.error.main, 0.15)
    },
    imported: {
        background: fade(theme.palette.success.main, 0.15)
    },
    test: {
        background: fade(theme.palette.info.main, 0.15)
    }
}));

const donationColumns: GridColDef[] = [
    {
        field: 'id',
        headerName: 'ID',
        width: 100,
        hide: true
    },
    {
        field: 'provider',
        headerName: 'Provider',
        width: 140,
        hide: true
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 140,
        hide: true,
        renderCell: params => {
            if (!params.value) {
                return null;
            }

            switch (params.value) {
                case 1: return 'Incomplete';
                case 2: return 'Failed';
                case 3: return 'Complete';
                case 4: return 'Imported';
                default: return 'Unknown';
            }
        }
    },
    {
        field: 'date',
        headerName: 'Date',
        width: 140,
        renderCell: params => {
            if (!params.value) {
                return null;
            }

            return DateTime.fromISO(params.value as string).toFormat('dd/MM/yyyy');
        }
    },
    {
        field: 'dateTime',
        headerName: 'Date & Time',
        width: 200,
        hide: true,
        valueGetter: params => params.row.date,
        renderCell: params => {
            if (!params.value) {
                return null;
            }

            return DateTime.fromISO(params.value as string).toFormat('dd/MM/yyyy HH:mm:ss');
        }
    },
    {
        field: 'firstName',
        headerName: 'First Name',
        flex: 1
    },
    {
        field: 'lastName',
        headerName: 'Last Name',
        flex: 1
    },
    {
        field: 'type',
        headerName: 'Type',
        width: 140,
        renderCell: params => {
            if (!params.value) {
                return null;
            }

            switch (params.value) {
                case 1: return 'One Time';
                case 2: return 'Regular Giving';
                default: return 'Unknown';
            }
        }
    },
    {
        field: 'amount',
        headerName: 'Amount',
        width: 140,
        renderCell: params => {
            if (!params.value) {
                return null;
            }

            return '$' + parseFloat(params.value as string).toFixed(2);
        }
    },

];

const Donations: FunctionComponent = () => {
    const classes = useStyles();
    const history = useHistory();

    const { data, loading, error } = useDonations();

    const getClassName = useCallback(() => {
        return classes.cell
    }, [classes.cell])

    const getRowClassName = useCallback((params: GridRowParams) => {
        const isTest = params.row.test;

        return clsx(
            classes.row,
            { [classes.incomplete]: !isTest && params.row.status === 1 },
            { [classes.failed]: !isTest && params.row.status === 2 },
            { [classes.imported]: !isTest && params.row.status === 4 },
            { [classes.test]: isTest },
        );
    }, [classes.row, classes.incomplete, classes.failed, classes.imported, classes.test])

    const onRowClick = useCallback((params: GridRowParams) => {
        history.push(`/donations/${params.row.provider}/${params.row.id}`);
    }, [history]);

    if (loading) {
        return (
            <Loading />
        );
    }

    if (error) {
        return (
            <div className={classes.error}>
                <Error
                    title="Error"
                    description="An error occurred and the list of donations could not be loaded. Please refresh the page and try again."
                    responseStatus={error}
                />
            </div>
        );
    }

    return (
        <div>
            <Typography variant="h1" gutterBottom>
                Donations
            </Typography>
            <Table
                columns={donationColumns}
                rows={data ?? []}
                disableColumnFilter
                autoHeight
                getCellClassName={getClassName}
                getRowClassName={getRowClassName}
                onRowClick={onRowClick}
            />
            {/*<pre>*/}
            {/*    {JSON.stringify(data, null, '\t')}*/}
            {/*</pre>*/}
        </div>
    );
};

export default Donations;
