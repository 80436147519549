import { makeStyles, Typography } from '@material-ui/core';
import React, { FunctionComponent, useContext } from 'react';
import { Redirect, Route, Switch, BrowserRouter } from 'react-router-dom';
import Loading from '../components/layout/Loading';
import useImportableDonationStats from '../hooks/useImportableDonationStats';
import Error from '../components/layout/Error';
import useImportablePurchaseStats from '../hooks/useImportablePurchaseStats';


const useStyles = makeStyles((theme) => ({
    error: {
        padding: theme.spacing(3, 0)
    }
}));

const Dashboard: FunctionComponent = () => {
    const classes = useStyles();
    const { data: importableDonationStats, loading: importableDonationStatsLoading, error: importableDonationStatsError } = useImportableDonationStats();
    const { data: importablePurchaseStats, loading: importablePurchaseStatsLoading, error: importablePurchaseStatsError } = useImportablePurchaseStats();

    if (importableDonationStatsLoading ||
        importablePurchaseStatsLoading) {
        return (
            <Loading />
        );
    }

    if (importableDonationStatsError ||
        importablePurchaseStatsError) {
        return (
            <div className={classes.error}>
                <Error
                    title="Error"
                    description="An error occurred and the stats could not be loaded. Please refresh the page and try again."
                    responseStatus={importableDonationStatsError ?? importablePurchaseStatsError}
                />
            </div>
        );
    }

    return (
        <div>
            <Typography variant="h1">
                Dashboard
            </Typography>
            <Typography variant="h2">
                Donations
            </Typography>
            <pre>
                {JSON.stringify(importableDonationStats, null, '\t')}
            </pre>
            <Typography variant="h2">
                Purchases
            </Typography>
            <pre>
                {JSON.stringify(importablePurchaseStats, null, '\t')}
            </pre>
        </div>
    );
};

export default Dashboard;
