import { fade, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { FunctionComponent, useCallback, useContext } from 'react';
import { Redirect, Route, Switch, BrowserRouter, useHistory, useParams } from 'react-router-dom';
import Loading from '../components/layout/Loading';
import Error from '../components/layout/Error';
import usePurchases from '../hooks/usePurchases';
import { GridColDef, GridRowParams } from '@material-ui/data-grid';
import Table from '../components/table/Table';
import { DateTime } from 'luxon';
import clsx from 'clsx';
import usePurchase from '../hooks/usePurchase';
import Data from '../components/data/Data';
import { DataType } from '../api/dtos';

export interface IPurchaseParams {
    id: string;
    provider: string;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    error: {
        padding: theme.spacing(3, 0)
    },
    cell: {
        cursor: 'pointer',

        '&:focus': {
            outline: 'none !important'
        }
    },
    row: {
    },
    incomplete: {
        background: fade(theme.palette.warning.main, 0.15)
    },
    failed: {
        background: fade(theme.palette.error.main, 0.15)
    },
    imported: {
        background: fade(theme.palette.success.main, 0.15)
    },
    test: {
        background: fade(theme.palette.info.main, 0.15)
    },
    summary: {
        width: '100%',
        marginBottom: theme.spacing(3)
    },
    data: {
        width: '100%',
        marginBottom: theme.spacing(3)
    }
}));



const Purchase: FunctionComponent = () => {
    const classes = useStyles();
    const history = useHistory();

    const { id, provider } = useParams<IPurchaseParams>();
    const { data, loading, error } = usePurchase(provider, id);

    if (loading) {
        return (
            <Loading />
        );
    }

    if (error) {
        return (
            <div className={classes.error}>
                <Error
                    title="Error"
                    description="An error occurred and the purchase could not be loaded. Please refresh the page and try again."
                    responseStatus={error}
                />
            </div>
        );
    }

    let status = 'Unknown';

    switch (data?.status) {
        case 1:
            status = 'Incomplete';
            break;
        case 2:
            status = 'Failed';
            break;
        case 3:
            status = 'Complete';
            break;
        case 4:
            status = 'Cancelled';
            break;
        case 5:
            status = 'Refunded';
            break;
        case 6:
            status = 'Imported';
            break;
    }

    let type = 'Unknown';


    let amount = '$' + (data?.total ?? 0).toFixed(2);

    return (
        <div className={classes.root}>
            <Typography variant="h1" gutterBottom>
                Purchase
            </Typography>

            <Typography variant="h2" gutterBottom>
                Summary
            </Typography>

            <Paper className={classes.summary}>
                <Data
                    name="id"
                    type={DataType.Integer}
                    label="ID"
                    value={data?.id?.toString()}
                />
                <Data
                    name="provider"
                    type={DataType.String}
                    label="Provider"
                    value={data?.provider}
                />
                <Data
                    name="firstName"
                    type={DataType.String}
                    label="First Name"
                    value={data?.firstName}
                />
                <Data
                    name="lastName"
                    type={DataType.String}
                    label="Last Name"
                    value={data?.lastName}
                />
                <Data
                    name="total"
                    type={DataType.String}
                    label="Total"
                    value={amount}
                />
                <Data
                    name="type"
                    type={DataType.String}
                    label="Type"
                    value={type}
                />
                <Data
                    name="status"
                    type={DataType.String}
                    label="Status"
                    value={status}
                />
                <Data
                    name="date"
                    type={DataType.DateTime}
                    label="Date"
                    value={data?.date}
                />
                <Data
                    name="Test"
                    type={DataType.Boolean}
                    label="Test"
                    value={data?.test ? 'True' : 'False' }
                />
            </Paper>
            <Typography variant="h2" gutterBottom>
                Data
            </Typography>
            <Paper className={classes.data}>
                {
                    data?.data?.map(x => (
                        <Data
                            key={x.name}
                            name={x.name}
                            type={x.type}
                            label={x.label}
                            value={x.value}
                        />
                    ))
                }
            </Paper>
            <Typography variant="h2" gutterBottom>
                Products
            </Typography>
            {
                data?.products?.map(y => (
                    <div>
                        <Typography variant="h3" gutterBottom>
                            Product - {y.name}
                        </Typography>
                        <Paper className={classes.data}>
                            {
                                y?.data?.map(x => (
                                    <Data
                                        key={x.name}
                                        name={x.name}
                                        type={x.type}
                                        label={x.label}
                                        value={x.value}
                                    />
                                ))
                            }
                        </Paper>
                    </div>
                ))
            }
            {/*<pre>*/}
            {/*    {JSON.stringify(data, null, '\t')}*/}
            {/*</pre>*/}
        </div>
    );
};

export default Purchase;
