import React, { FunctionComponent, useMemo } from 'react';
import { AuthenticateResponse } from '../../api/dtos';
import AuthenticationActionContext from '../../contexts/AuthenticationActionContext';
import AuthenticationContext from '../../contexts/AuthenticationContext';
import useAuthentication from '../../hooks/useAuthentication';
import Error from '../layout/Error';
import Loading from '../layout/Loading';

interface IAuthenticationProviderProps {
    initialAuthentication: AuthenticateResponse | null;
}

const AuthenticationProvider: FunctionComponent<IAuthenticationProviderProps> = ({
    initialAuthentication,
    children
}) => {
    const { data, error, loading, mutate } = useAuthentication({
        initialData: initialAuthentication ?? undefined,
        refreshInterval: 300000
    });

    const actions = useMemo(() => {
        return {
            mutate
        };
    }, [mutate])

    if (loading) {
        return (
            <Loading />
        );
    }

    if (error) {
        return (
            <Error
                title="Error"
                description="An error occurred while attempting to retrieve the logged on session."
                responseStatus={error}
            />
        );
    }

    return (
        <AuthenticationActionContext.Provider value={actions}>
            <AuthenticationContext.Provider value={data ?? null}>
                {children}
            </AuthenticationContext.Provider>
        </AuthenticationActionContext.Provider>
    )
};

export default AuthenticationProvider;