import { faHandHoldingUsd, faHome, faShoppingCart } from '@fortawesome/pro-duotone-svg-icons';
import React, { FunctionComponent, useContext } from 'react';
import { Redirect, Route, Switch, BrowserRouter } from 'react-router-dom';
import Layout, { ILink } from './components/layout/Layout';
import Dashboard from './containers/Dashboard';
import Donation from './containers/Donation';
import Donations from './containers/Donations';
import Purchase from './containers/Purchase';
import Purchases from './containers/Purchases';


const links: ILink[] = [
    {
        icon: faHome,
        newTab: false,
        route: true,
        title: 'Dashboard',
        url: '/dashboard'
    },
    {
        icon: faHandHoldingUsd,
        newTab: false,
        route: true,
        title: 'Donations',
        url: '/donations'
    },
    {
        icon: faShoppingCart,
        newTab: false,
        route: true,
        title: 'Purchases',
        url: '/purchases'
    }
];

const App: FunctionComponent = () => {

    return (
        <BrowserRouter>
            <Layout
                links={links}
            >
                <Switch>
                    <Route
                        path="/donations/:provider/:id"
                    >
                        <Donation />
                    </Route>
                    <Route
                        path="/donations"
                    >
                        <Donations />
                    </Route>
                    <Route
                        path="/purchases/:provider/:id"
                    >
                        <Purchase />
                    </Route>
                    <Route
                        path="/purchases"
                    >
                        <Purchases />
                    </Route>
                    <Route
                        path="/dashboard"
                    >
                        <Dashboard />
                    </Route>
                    <Redirect
                        path="*"
                        to="/dashboard"
                    />
                </Switch>
            </Layout>
        </BrowserRouter>
    );
};

export default App;
