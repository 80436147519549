import { CircularProgress } from '@material-ui/core';
import React, { FunctionComponent } from 'react';


interface ILoadingProps {
}

const Loading: FunctionComponent<ILoadingProps> = ({}) => {
    return (
        <div>
            <CircularProgress />
        </div>
    )
};

export default Loading;