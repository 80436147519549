import { JsonServiceClient } from '@servicestack/client';
import { useCallback, useContext } from 'react';
import useSWR, { SWRConfiguration, mutate } from 'swr';
import { GetDonationsResponse, GetDonations } from '../api/dtos';
import ServiceStackClientContext from '../contexts/ServiceStackClientContext';
import { BoundMutator } from '../utilities/BoundMutator';


// @ts-ignore: declared but not used 'url'
const fetcher = async (url: string, client: JsonServiceClient) => {
    return await client?.get(new GetDonations());
}

const urlKey = '/api/donations';

const useDonations = (configuration?: SWRConfiguration<GetDonationsResponse, GetDonationsResponse>) => {
    const client = useContext(ServiceStackClientContext);

    const { data, error } = useSWR<GetDonationsResponse, GetDonationsResponse>([urlKey, client], fetcher, configuration);

    const boundMutate: BoundMutator<GetDonationsResponse> = useCallback((data, shouldRevalidate) => {
        return mutate([urlKey, client], data, shouldRevalidate);
    }, [client]);

    return {
        data: data?.donations,
        error: error?.responseStatus,
        loading: data === undefined && error === undefined,
        mutate: boundMutate
    };
}

export default useDonations;