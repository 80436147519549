import { faChevronLeft, faChevronRight, faUserCircle } from '@fortawesome/pro-duotone-svg-icons';
//import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Button, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, Menu, MenuItem, Toolbar, Tooltip, Typography, useTheme } from '@material-ui/core';
import React, { FunctionComponent, MouseEvent, useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import AuthenticationContext from '../../contexts/AuthenticationContext';
import logo from '../../assets/img/logo-small.png';
import { faBars, faTimes } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';

export interface ILink
{
    url: string;
    icon: any;
    newTab: boolean;
    route: boolean;
    title: string;
}

interface ILayoutProps {
    links: ILink[];
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    //root: {
    //    flexGrow: 1,
    //},
    logo: {
        width: 40,
        height: 40,
        padding: 4,
        borderRadius: '50%',
        overflow: 'hidden',
        marginRight: theme.spacing(2),
        background: theme.palette.common.white
    },
    logoImage: {
        width: '100%'
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    titleLink: {
        marginRight: 'auto',
        textDecoration: 'none'
    },
    title: {
        color: theme.palette.common.white,
        textDecoration: 'none'
    },

    root: {
        display: 'flex',
        width: '100%'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: 0,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(8) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        width: '100%'
    },
    link: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        minHeight: 50
    },
    active: {
        background: theme.palette.grey[300]
    },
    icon: {
        fontSize: 24
    },
    iconCenter: {
    }
}));

const Layout: FunctionComponent<ILayoutProps> = ({ children, links }) => {
    const authentication = useContext(AuthenticationContext);

    const theme = useTheme();

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const open = Boolean(anchorEl);

    const handleMenu = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [drawOpen, setDrawOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setDrawOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawOpen(false);
    };

    return (
        <div className={classes.root}>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar)}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                          [classes.hide]: drawOpen,
                        })}
                    >
                        <FontAwesomeIcon fixedWidth icon={faBars} />
                    </IconButton>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerClose}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: !drawOpen,
                        })}
                    >
                        <FontAwesomeIcon fixedWidth icon={faTimes} />
                    </IconButton>
                    <div className={classes.logo}>
                        <img className={classes.logoImage} alt="Breast Cancer Trials" src={logo} />
                    </div>
                    <Link to="/" className={classes.titleLink}>
                        <Typography variant="h6" className={classes.title}>
                            Website Importer
                        </Typography>
                    </Link>
                    {
                        authentication && (
                            <>
                                <IconButton
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                >
                                    <FontAwesomeIcon icon={faUserCircle} size="lg" />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <MenuItem>{authentication.displayName ?? authentication.userName}</MenuItem>
                                    <Divider />
                                    <MenuItem component="a" href="/auth/logout" onClick={handleClose}>Logout</MenuItem>
                                </Menu>
                            </>
                        )
                    }
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: drawOpen,
                    [classes.drawerClose]: !drawOpen,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: drawOpen,
                        [classes.drawerClose]: !drawOpen,
                    }),
                }}
            >
                <div className={classes.toolbar} />
                <List disablePadding>
                    {
                        links?.map((link, i) => {
                            let linkComponent = null;
                            if (link.newTab || !link.route) {
                                linkComponent = (
                                    <ListItem button component="a" href={link.url} className={classes.link} key={i}>
                                        <ListItemIcon className={clsx(classes.icon, { [classes.iconCenter]: !drawOpen })} ><FontAwesomeIcon fixedWidth icon={link.icon} /></ListItemIcon>
                                        <ListItemText primary={link.title} className={clsx({ [classes.hide]: !drawOpen })} />
                                    </ListItem>
                                );
                            }
                            else {
                                linkComponent = (
                                    <ListItem button component={NavLink} to={link.url} className={classes.link} activeClassName={classes.active} key={i}>
                                        <ListItemIcon className={clsx(classes.icon, { [classes.iconCenter]: !drawOpen })} ><FontAwesomeIcon fixedWidth icon={link.icon} /></ListItemIcon>
                                        <ListItemText primary={link.title} className={clsx({ [classes.hide]: !drawOpen })} />
                                    </ListItem>
                                );
                            }


                            if (drawOpen) {
                                return linkComponent;
                            }

                            return (
                                <Tooltip title={link.title} placement="right" key={i}>
                                    {linkComponent}
                                </Tooltip>
                            );
                        })
                    }
                </List>
            </Drawer>
            <div className={classes.content}>
                <div className={classes.toolbar} />
                {children}
            </div>
        </div>
    )
};

export default Layout;