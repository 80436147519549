import { JsonServiceClient } from '@servicestack/client';
import { useCallback, useContext } from 'react';
import useSWR, { SWRConfiguration, mutate } from 'swr';
import { Authenticate, AuthenticateResponse } from '../api/dtos';
import ServiceStackClientContext from '../contexts/ServiceStackClientContext';
import { BoundMutator } from '../utilities/BoundMutator';


// @ts-ignore: declared but not used 'url'
const fetcher = async (url: string, client: JsonServiceClient) => {
    try {
        return await client?.get(new Authenticate());
    }
    catch (error) {
        if (error?.responseStatus?.message === "Not Authenticated") {
            return null as unknown as AuthenticateResponse;
        }

        throw error;
    }
}

const useAuthentication = (configuration?: SWRConfiguration<AuthenticateResponse, AuthenticateResponse>) => {
    const client = useContext(ServiceStackClientContext);

    const { data, error } = useSWR<AuthenticateResponse, AuthenticateResponse>(['/auth', client], fetcher, configuration);

    const unauthenticated = error?.responseStatus?.message === "Not Authenticated";

    const boundMutate: BoundMutator<AuthenticateResponse> = useCallback((data, shouldRevalidate) => {
        return mutate(['/auth', client], data, shouldRevalidate);
    }, [client]);

    return {
        data,
        error: unauthenticated ? undefined : error?.responseStatus,
        loading: data === undefined && error === undefined,
        mutate: boundMutate
    };
}

export default useAuthentication;