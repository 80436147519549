import React from 'react';
import ReactDOM from 'react-dom';

import reportWebVitals from './reportWebVitals';
import App from './App';
import AuthenticationProvider from './components/provider/AuthenticationProvider';
import { AuthenticateResponse } from './api/dtos';
import ServiceStackClientProvider from './components/provider/ServiceStackClientProvider';
import { JsonServiceClient } from '@servicestack/client';
import { createTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const authentication: AuthenticateResponse | null = global.AUTH ?? null;
const client = new JsonServiceClient();

if (!authentication || !authentication.userName) {
    if (global.location.search) {
        global.location.href = '/auth/identity-server' + global.location.search;
    } else {
        global.location.href = '/auth/identity-server';
    }
}

const primaryColor = '#562c85';
const secondaryColor = '#e8078c';

const themeOptions = {
    palette: {
        primary: {
            main: primaryColor
        },
        secondary: {
            main: secondaryColor
        }

    },
    typography: {
        h1: {
            fontSize: '3rem',
            color: secondaryColor
        },
        h2: {
            fontSize: '1.5rem',
            color: primaryColor
        },
        h3: {
            fontSize: '1.25rem',
            color: secondaryColor
        },
        h4: {
            fontSize: '1.20rem',
            color: primaryColor
        },
        h5: {
            fontSize: '1.15rem',
            color: secondaryColor
        },
        h6: {
            fontSize: '1.10rem',
            color: primaryColor
        }
    }
}

const theme = createTheme(themeOptions);

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={LuxonUtils}>
                <ServiceStackClientProvider serviceStackClient={client}>
                    <AuthenticationProvider initialAuthentication={authentication} >
                        <CssBaseline />
                        <App />
                    </AuthenticationProvider>
                </ServiceStackClientProvider>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
