/* Options:
Date: 2021-09-03 15:07:38
Version: 5.120
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:44352

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IPost
{
}

export interface IGet
{
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class DonationStats
{
    public count?: number;
    public minimum?: number;
    public maximum?: number;
    public total?: number;
    public average?: number;

    public constructor(init?: Partial<DonationStats>) { (Object as any).assign(this, init); }
}

export enum DonationType
{
    OneTime = 1,
    Recurring = 2,
}

export enum DonationStatus
{
    Incomplete = 1,
    Failed = 2,
    Complete = 3,
    Imported = 4,
}

export enum DataType
{
    String = 1,
    Integer = 2,
    Decimal = 3,
    Boolean = 4,
    DateTime = 5,
    DateTimeOffset = 6,
    Currency = 7,
}

export class Data
{
    public name: string;
    public label: string;
    public value: string;
    public type?: DataType;

    public constructor(init?: Partial<Data>) { (Object as any).assign(this, init); }
}

export class Donation
{
    public id: string;
    public provider: string;
    public firstName: string;
    public lastName: string;
    public amount?: number;
    public total?: number;
    public type?: DonationType;
    public status?: DonationStatus;
    public date?: string;
    public test?: boolean;
    public data: Data[];

    public constructor(init?: Partial<Donation>) { (Object as any).assign(this, init); }
}

export enum PurchaseStatus
{
    Incomplete = 1,
    Failed = 2,
    Complete = 3,
    Cancelled = 4,
    Refunded = 5,
    Imported = 6,
}

export class PurchaseProduct
{
    public name: string;
    public quantity?: number;
    public totalAmount?: number;
    public data: Data[];

    public constructor(init?: Partial<PurchaseProduct>) { (Object as any).assign(this, init); }
}

export class Purchase
{
    public id: string;
    public provider: string;
    public firstName: string;
    public lastName: string;
    public total?: number;
    public status?: PurchaseStatus;
    public date?: string;
    public test?: boolean;
    public data: Data[];
    public products: PurchaseProduct[];

    public constructor(init?: Partial<Purchase>) { (Object as any).assign(this, init); }
}

export class PurchaseStats
{
    public count?: number;
    public minimum?: number;
    public maximum?: number;
    public total?: number;
    public average?: number;

    public constructor(init?: Partial<PurchaseStats>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserApiKey
{
    // @DataMember(Order=1)
    public key: string;

    // @DataMember(Order=2)
    public keyType: string;

    // @DataMember(Order=3)
    public expiryDate?: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UserApiKey>) { (Object as any).assign(this, init); }
}

export class GetDonationsResponse
{
    public responseStatus: ResponseStatus;
    public donations: Donation[];

    public constructor(init?: Partial<GetDonationsResponse>) { (Object as any).assign(this, init); }
}

export class GetImportableDonationStatsResponse
{
    public responseStatus: ResponseStatus;
    public donationStats: DonationStats;

    public constructor(init?: Partial<GetImportableDonationStatsResponse>) { (Object as any).assign(this, init); }
}

export class GetDonationResponse
{
    public responseStatus: ResponseStatus;
    public donation: Donation;

    public constructor(init?: Partial<GetDonationResponse>) { (Object as any).assign(this, init); }
}

export class GetPurchasesResponse
{
    public responseStatus: ResponseStatus;
    public purchases: Purchase[];

    public constructor(init?: Partial<GetPurchasesResponse>) { (Object as any).assign(this, init); }
}

export class GetImportablePurchaseStatsResponse
{
    public responseStatus: ResponseStatus;
    public purchaseStats: PurchaseStats;

    public constructor(init?: Partial<GetImportablePurchaseStatsResponse>) { (Object as any).assign(this, init); }
}

export class GetPurchaseResponse
{
    public responseStatus: ResponseStatus;
    public purchase: Purchase;

    public constructor(init?: Partial<GetPurchaseResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetApiKeysResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RegenerateApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RegenerateApiKeysResponse>) { (Object as any).assign(this, init); }
}

// @Route("/api/donations")
export class GetDonations implements IReturn<GetDonationsResponse>
{

    public constructor(init?: Partial<GetDonations>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetDonationsResponse(); }
    public getTypeName() { return 'GetDonations'; }
}

// @Route("/api/donations/stats/importable")
export class GetImportableDonationStats implements IReturn<GetImportableDonationStatsResponse>
{

    public constructor(init?: Partial<GetImportableDonationStats>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetImportableDonationStatsResponse(); }
    public getTypeName() { return 'GetImportableDonationStats'; }
}

// @Route("/api/donation/{Provider}/{Id}")
export class GetDonation implements IReturn<GetDonationResponse>
{
    public provider: string;
    public id: string;

    public constructor(init?: Partial<GetDonation>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetDonationResponse(); }
    public getTypeName() { return 'GetDonation'; }
}

// @Route("/api/donation/test")
export class TestDonations implements IReturn<Donation[]>
{

    public constructor(init?: Partial<TestDonations>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<Donation>(); }
    public getTypeName() { return 'TestDonations'; }
}

// @Route("/api/purchase/test")
export class TestPurchases implements IReturn<Purchase[]>
{

    public constructor(init?: Partial<TestPurchases>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<Purchase>(); }
    public getTypeName() { return 'TestPurchases'; }
}

// @Route("/api/purchases")
export class GetPurchases implements IReturn<GetPurchasesResponse>
{

    public constructor(init?: Partial<GetPurchases>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPurchasesResponse(); }
    public getTypeName() { return 'GetPurchases'; }
}

// @Route("/api/purchases/stats/importable")
export class GetImportablePurchaseStats implements IReturn<GetImportablePurchaseStatsResponse>
{

    public constructor(init?: Partial<GetImportablePurchaseStats>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetImportablePurchaseStatsResponse(); }
    public getTypeName() { return 'GetImportablePurchaseStats'; }
}

// @Route("/api/purchase/{Provider}/{Id}")
export class GetPurchase implements IReturn<GetPurchaseResponse>
{
    public provider: string;
    public id: string;

    public constructor(init?: Partial<GetPurchase>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPurchaseResponse(); }
    public getTypeName() { return 'GetPurchase'; }
}

// @Route("/auth")
// @Route("/auth/{provider}")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=16)
    public useTokenCookie?: boolean;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AuthenticateResponse(); }
    public getTypeName() { return 'Authenticate'; }
}

// @Route("/assignroles")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new AssignRolesResponse(); }
    public getTypeName() { return 'AssignRoles'; }
}

// @Route("/unassignroles")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new UnAssignRolesResponse(); }
    public getTypeName() { return 'UnAssignRoles'; }
}

// @Route("/apikeys")
// @Route("/apikeys/{Environment}")
// @DataContract
export class GetApiKeys implements IReturn<GetApiKeysResponse>, IGet
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<GetApiKeys>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetApiKeysResponse(); }
    public getTypeName() { return 'GetApiKeys'; }
}

// @Route("/apikeys/regenerate")
// @Route("/apikeys/regenerate/{Environment}")
// @DataContract
export class RegenerateApiKeys implements IReturn<RegenerateApiKeysResponse>, IPost
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<RegenerateApiKeys>) { (Object as any).assign(this, init); }
    public createResponse() { return new RegenerateApiKeysResponse(); }
    public getTypeName() { return 'RegenerateApiKeys'; }
}

