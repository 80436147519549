import { JsonServiceClient } from '@servicestack/client';
import { useCallback, useContext, useMemo } from 'react';
import useSWR, { SWRConfiguration, mutate } from 'swr';
import { GetDonationResponse, GetDonation } from '../api/dtos';
import ServiceStackClientContext from '../contexts/ServiceStackClientContext';
import { BoundMutator } from '../utilities/BoundMutator';


// @ts-ignore: declared but not used 'url'
const fetcher = async (url: string, client: JsonServiceClient, provider: string, id: string) => {
    return await client?.get(new GetDonation({provider, id}));
}

const urlKey = '/api/donation';

const useDonations = (provider?: string, id?: string, configuration?: SWRConfiguration<GetDonationResponse, GetDonationResponse>) => {
    const client = useContext(ServiceStackClientContext);

    const cacheKey = useMemo(() => {
        if (!provider || !id) {
            return null;
        }

        return [urlKey, client, provider, id]
    }, [client, provider, id])

    const { data, error } = useSWR<GetDonationResponse, GetDonationResponse>(cacheKey, fetcher, configuration);

    const boundMutate: BoundMutator<GetDonationResponse> = useCallback((data, shouldRevalidate) => {
        return mutate(cacheKey, data, shouldRevalidate);
    }, [cacheKey]);

    return {
        data: data?.donation,
        error: error?.responseStatus,
        loading: data === undefined && error === undefined,
        mutate: boundMutate
    };
}

export default useDonations;