import React, { FunctionComponent, useMemo } from 'react';
import { JsonServiceClient } from '@servicestack/client'
import ServiceStackClientContext from '../../contexts/ServiceStackClientContext';

interface IServiceStackClientProviderProps {
    serviceStackClient: JsonServiceClient;
}

const ServiceStackClientProvider: FunctionComponent<IServiceStackClientProviderProps> = ({
    serviceStackClient,
    children
}) => {
    return (
        <ServiceStackClientContext.Provider value={serviceStackClient}>
            {children}
        </ServiceStackClientContext.Provider>
    )
};

export default ServiceStackClientProvider;